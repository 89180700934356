import { useRef, useEffect } from "react";

const DrumPad = ({ drumKey, audio, pressedKey, setPressedKey }) => {

    const audioRef = useRef();
    useEffect(() => {
    }, [])

    return (
        <button
            className="drum-pad"
            id={drumKey}
            onClick={() => {
                audioRef.current.play();
                setPressedKey(audio);
            }}
        >
            <audio
                ref={audioRef}
                className="clip"
                id={drumKey}
                src={require(`../assets/audio/${audio}.mp3`)}></audio>

            {drumKey}
        </button >
    )
}

export default DrumPad;