import './App.css';
import DrumPad from './components/DrumPad';
import drumData from "./drumData.json";
import { useState, useEffect } from 'react';



function App() {

  const [pressedKey, setPressedKey] = useState("");

  const handleKeyPress = (e) => {
    const currentKeypress = e.key.toUpperCase();
    setPressedKey(prevState => drumData[currentKeypress]);
    const currentElement = document.getElementById(currentKeypress);
    if (!!currentElement) {
      currentElement.children[currentKeypress].play();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    }
  }, [pressedKey])

  return (
    <main id="drum-machine">
      <p id="display">{pressedKey}</p>
      <section
        className="drum-pad-area"
      >
        {Object.keys(drumData).map((key) => {
          return (<DrumPad
            setPressedKey={setPressedKey}
            pressedKey={pressedKey}
            key={key}
            drumKey={key}
            audio={drumData[key]} />)
        })}
      </section>
    </main>
  );
}

export default App;
